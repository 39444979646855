<template>
  <div>
    <v-container fluid>
      <v-alert
          dense
          :type="alert.type"
          timeout="5000"
          v-if="alert.message">
        {{ alert.message }}
      </v-alert>
      <v-card>
        <v-card-title>
          <p><v-btn
              color="primary"
              @click.stop="openCreator();"
          >Create
            <v-icon
                dark
                right
            >
              mdi-plus
            </v-icon></v-btn></p>
          <v-spacer />
            Discount Via Country List
          <v-spacer/>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          />
        </v-card-title>
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="itemsWithIndex"
              :search="search"
              :loading="Overlay"
              loading-text="Loading... Please wait"
              :footer-props="{'items-per-page-options':[15,30,50,100]}"
              :items-per-page="30"
          >
            <template v-slot:[`item.discount_country`]="{ item }">
              {{ item.discount_country }} <gb-flag
                :code="item.discount_country.toLowerCase()"
                size="mini"
            />
            </template>
            <template v-slot:[`item.discount_seller`]="{ item }">
              <template v-if="modify_seller.id !== item.discount_id">
                <v-autocomplete
                    :value="item.discount_seller"
                    :items="Sellers"
                    label="Sellers"
                    rounded
                    solo
                    dark
                    multiple
                    readonly
                />
                <br />
                <v-btn
                  color="blue"
                  class="mx-2"
                  dark
                  @click="updateSeller(item)"
                  :disabled="modify_seller.id !== item.discount_id && modify_seller.id !== null"
                >
                  Update
                </v-btn>
                <v-btn
                  color="red"
                  class="mx-2"
                  dark
                  @click="doDeletePackage(item)"
                >
                  Delete
                </v-btn>
              </template>
              <template v-else>
                <v-autocomplete
                    v-model="modify_seller.sellers"
                    :items="Sellers"
                    label="Sellers"
                    rounded
                    solo
                    dark
                    multiple
                />
                <br />
                <v-btn
                    color="green"
                    class="mx-2"
                    dark
                    @click="doUpdatePackage()"
                    :disabled="modify_seller.id !== item.discount_id && modify_seller.id !== null"
                >
                  Save
                </v-btn>
                <v-btn
                    color="orange"
                    class="mx-2"
                    dark
                    @click="cancelSeller()"
                    :disabled="modify_seller.id !== item.discount_id && modify_seller.id !== null"
                >
                  Cancel
                </v-btn>
              </template>
            </template>
            <template v-slot:[`item.discount`]="{ item }">
              <v-simple-table
                  fixed-header
                  height="200px"
              >
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">
                      <h3>Package</h3>
                    </th>
                    <th class="text-left">
                      <h3>Price</h3>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(data,index) in packages"
                      :key="index"
                  >
                    <td class="text-left">{{ data }}</td>
                    <td class="text-left" v-if="item.discount[index] === '-1'">
                      <v-btn
                          class="ma-2"
                          color="red"
                          dark
                          small
                          text
                          @click="doToggleModifyDiscountPackage(item)"
                      >
                        Disabled
                        <v-icon
                            dark
                            right
                        >
                          mdi-lock
                        </v-icon>
                      </v-btn>
                    </td>
                    <td class="text-left" v-if="item.discount[index] !== '-1'">
                      <v-btn
                          class="ma-2"
                          color="green"
                          dark
                          small
                          text
                          @click="doToggleModifyDiscountPackage(item)"
                      >
                        {{item.discount[index]}}
                        <v-icon
                            dark
                            right
                        >
                          mdi-currency-usd
                        </v-icon>
                      </v-btn>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
    <v-dialog
        v-model="new_package"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          New Package
        </v-card-title>
        <v-card-text>
          <v-item-group>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="dpackage.discount_country"
                      :items="Countries"
                      label="Country"
                      required
                      clearable
                      rounded
                      solo
                      dark
                  />
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="dpackage.discount_seller"
                      :items="Sellers"
                      label="Sellers"
                      required
                      clearable
                      rounded
                      solo
                      dark
                      multiple
                  />
                </v-col>
                <v-col
                    v-for="(data, index) in packages"
                    :key="index"
                    cols="12"
                    md="4"
                >
                  <v-item>
                    <v-text-field
                      v-model="dpackage['discount'][index]"
                      hint="Disabled: -1"
                      persistent-hint
                      :rules="[v => !!v || 'Discount is required']"
                      :label="data"
                    >
                      <v-icon
                          v-slot="prepend"
                      >
                        mdi-wallet
                      </v-icon>
                    </v-text-field>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            color="green"
            :disabled="!isValid || createPackageState"
            :loading="createPackageState"
            @click="doCreatePackage()"
          >
            Create
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            color="primary"
            @click="new_package = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <Loader :status="createPackageState" />
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="modify_package_discount"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          Modify Package
        </v-card-title>
        <v-card-text>
          <v-item-group>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="modify_discount.discount_country"
                      :items="Countries"
                      label="Country"
                      readonly
                      rounded
                      solo
                      dark
                  />
                </v-col>
                <v-col
                    v-for="(data, index) in packages"
                    :key="index"
                    cols="12"
                    md="4"
                >
                  <v-item>
                    <v-text-field
                      v-model="modify_discount['discount'][index]"
                      hint="Disabled: -1"
                      persistent-hint
                      :rules="[v => !!v || 'Discount is required']"
                      :label="data"
                    >
                      <v-icon
                          v-slot="prepend"
                      >
                        mdi-wallet
                      </v-icon>
                    </v-text-field>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-card-text>
        <v-card-actions>
          <v-btn
            dark
            color="green"
            :disabled="!isValidModifyDiscount || updatePackageDiscountState"
            :loading="updatePackageDiscountState"
            @click="doUpdatePackageDiscount()"
          >
            Update
          </v-btn>
          <v-spacer />
          <v-btn
            dark
            color="primary"
            @click="modify_package_discount = false"
          >
            Close
          </v-btn>
        </v-card-actions>
        <Loader :status="updatePackageDiscountState" />
      </v-card>
    </v-dialog>
    <Loading :status="Overlay" />
  </div>
</template>

<script>
//import Alert from '../helpers/notice';
import Loading from '../components/Loading.vue';
import Loader from '../components/Loader.vue';
import {mapActions, mapState} from "vuex";
import CountryJson from '../json/countries.json';
import {fetchData} from "../helpers";
import Notice from '../helpers/notice';
export default {
  name: "DiscountViaCountry",
  components: {
    Loading,
    Loader,
  },
  data(){
    return {
      search: '',
      Overlay: false,
      packages:['1DAY','7DAY','15DAY','30DAY','7DAY-2HWID','30DAY-2HWID'],
      new_package: false,
      modify_package_discount: false,
      dpackage:{
        discount_country: '',
        discount: [],
        discount_seller: [],
      },
      modify_seller:{
        id: null,
        sellers: [],
      },
      modify_discount:{
        id: null,
        discount_country: '',
        discount: [],
      },
      sellers:[],
      items:[],
      headers: [
        { text: 'Country', align: 'center', sortable: false,value: 'discount_country',width:"auto" },
        { text: 'Packages', align: 'center', sortable: false,value: 'discount',width:"auto" },
        { text: 'Sellers', align: 'center', sortable: false,value: 'discount_seller',width:"auto" },
      ],
      createPackageState: false,
      updatePackageSellerState: false,
      updatePackageDiscountState: false,
    }
  },
  computed: {
    ...mapState('auth', ['status']),
    ...mapState({
      alert: state => state.alert
    }),
    Sellers(){
      return this.sellers.map((item) => ({
        value: item.seller_id,
        text: item.username,
      }));
    },
    Countries(){
      return Object.keys(CountryJson).map((key) => {
        return {
          value: key,
          text: CountryJson[key]
        };
      });
    },
    isValid(){
      const {discount_country, discount} = this.dpackage;
      const valid_country = !!discount_country;
      const valid_discount = discount.filter(d=>!!d).length === this.packages.length;
      return valid_country && valid_discount;
    },
    isValidModifyDiscount(){
      const {discount_country, discount} = this.modify_discount;
      const valid_country = !!discount_country;
      const valid_discount = discount.filter(d=>!!d).length === this.packages.length;
      return valid_country && valid_discount;
    },
    itemsWithIndex(){
      return this.items.map((item,index)=>({
        ...item,
        index:index,
      }));
    },
  },
  methods:{
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['success', 'error']),
    doToggleModifyDiscountPackage(item){
      const discount_data = JSON.parse(JSON.stringify(item));
      this.modify_discount = {
        id: discount_data.discount_id,
        discount_country: discount_data.discount_country,
        discount: discount_data.discount,
      };
      this.modify_package_discount = true;
    },
    cancelSeller(){
      this.modify_seller={
        id: null,
        sellers: [],
      };
    },
    updateSeller(item){
      this.modify_seller = {
        id: item.discount_id,
        sellers: item.discount_seller,
      }
    },
    openCreator(){
      this.package = {
          discount_country: '',
          discount: [],
          discount_seller: [],
      }
      this.new_package = true;
    },
    doUpdatePackage(){
      this.updatePackageSellerState = true;
      fetchData(`${this.$API}/api/DiscountViaCountry/update`,this.modify_seller)
          .then((json)=>{
            if(json.success){
              this.success(json.msg);
              this.cancelSeller();
              this.fetchPackages();
            }else{
              this.error(json.msg);
            }
          })
          .catch((error)=>{
            this.error(error.message);
          })
          .finally(()=>{
            this.updatePackageSellerState = false;
          });
    },
    doUpdatePackageDiscount(){
      this.updatePackageDiscountState = true;
      fetchData(`${this.$API}/api/DiscountViaCountry/update-discount`,this.modify_discount)
          .then((json)=>{
            if(json.success){
              this.success(json.msg);
              Notice.success({message:json.msg});
              this.fetchPackages();
              this.modify_package_discount = false;
            }else{
              this.error(json.msg);
              Notice.error({message:json.msg});
            }
          })
          .catch((error)=>{
            this.error(error.message);
          })
          .finally(()=>{
            this.updatePackageDiscountState = false;

          });
    },
    async doDeletePackage(item){
      const answer = await this.$confirm(`Do you really want to delete this package ? This data can't restore !`,{ title: 'Warning' });
      if (answer === true){
        this.Overlay = true;
        fetchData(`${this.$API}/api/DiscountViaCountry/delete`,{id:item.discount_id}, 'DELETE')
        .then((json)=>{
          if(json.success){
            this.success(json.msg);
            this.fetchPackages();
          }else{
            this.error(json.msg);
          }
        })
        .catch((error)=>{
          this.error(error.message);
        })
        .finally(()=>{
          this.Overlay = false;
        });
      }
    },
    doCreatePackage(){
      this.createPackageState = true;
      fetchData(`${this.$API}/api/DiscountViaCountry/create`,this.dpackage)
      .then((json)=>{
        if(json.success){
          this.success(json.msg);
          this.new_package = false;
          this.fetchPackages();
        }else{
          this.error(json.msg);
        }
      })
      .catch((error)=>{
        this.error(error.message);
      })
      .finally(()=>{
        this.createPackageState = false;
      });
    },
    fetchPackages(){
      return fetchData(`${this.$API}/api/DiscountViaCountry/get`).then((json)=>{
        this.items = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      });
    },
    fetchSellers(){
      return fetchData(`${this.$API}/api/Seller/getList`).then((json)=>{
        this.sellers = json;
      },(err)=>{
        if(err === 401){
          this.logout();
        }else{
          this.error('SERVER ERROR !!!');
        }
      });
    },
    fetchAll(){
      this.Overlay = true;
      Promise.all([
        this.fetchPackages(),
        this.fetchSellers(),
      ]).then(()=>{
        this.Overlay = false;
      });
    },
  },
  mounted() {
    this.fetchAll();
  }
}
</script>

<style scoped>

</style>